
import { Component, Provide, Vue, Watch } from 'nuxt-property-decorator'
import LazyHydrate from 'vue-lazy-hydration'
import EventBusMixin from '~/mixins/EventBusMixin.vue'
import Features from '~/components/Features.vue'

@Component({
  components: {
  Features,
  LazyHydrate,
  WebPushPopup: () => import('@pp-frontend/web-push/lib/components/WebPushPopup.vue'),
  MobileAppBanner: () => import('~/components/notify/banners/MobileAppBanner.vue'),
  Notify: () => import('@pp-frontend/authorization/component/Notify.vue')
  },
  mixins: [
  EventBusMixin
  ]
  })
export default class extends Vue {
  @Provide('excludeCacheFunction') clearPageCache: (name: string) => void = (name: string) => {
    this.keepAliveExclude.push(name)

    this.$nextTick(() => {
      this.keepAliveExclude.pop()
    })
  }

  keepAliveExclude: string[] = ['payment', 'payment-fail']
  notifyIsOpen: boolean = false
  popupComponent: any = () => import('~/components/Popup.vue')
  preloaderComponent: any = () => import('~/components/Preloader.vue')
  isShowFooter: boolean = true
  hideBody: boolean = false
  changeBg: boolean = false

  get isRouteLanding () {
    // роут - посадка: гибдд | почта
    return this.$route.name && ['gibdd-incoming', 'email-landing'].includes(this.$route.name)
  }

  get siteLogo () {
    return this.isRouteLanding
      ? require('~/assets/img/off_logo.webp')
      : require('~/assets/img/logo.webp')
  }

  beforeMount () {
    this.$ga.updateStorage()
  }

  mounted () {
    this.initInAppMessageHandler()
  }

  @Watch('$route', { deep: true, immediate: true })
  change () {
    const routeName = this.$route.name || ''
    this.isShowFooter = !['gibdd-incoming', 'payment', 'payment-fail'].includes(routeName)
    this.changeBg = ['payment-success', 'subscribe'].includes(routeName)
  }

  scrollTop () {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  initInAppMessageHandler () {
    if (this.$fireModule.messaging.isSupported()) {
      this.$fire.messaging.onMessage((e) => {
        const isConfirm = confirm(e.notification.body + ' Посмотреть?')

        if (isConfirm) {
          window.location.href = e.notification.click_action
        }
      })
    }
  }
}
